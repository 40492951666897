/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Polygon } from 'react-native-svg';

import { IconProps } from '../lib';

function Lightning(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Polygon
        points="96 240 112 160 48 136 160 16 144 96 208 120 96 240"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
    </Svg>
  );
}

export default Lightning;
