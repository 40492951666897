/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function FaceMask(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M208,136c0,51-66.2,68.8-78.2,71.6a7.3,7.3,0,0,1-3.6,0C114.2,204.8,48,187,48,136V77.4A7.9,7.9,0,0,1,53,70l72-28.8a7.8,7.8,0,0,1,6,0L203,70a7.9,7.9,0,0,1,5,7.4Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Line
        x1={88}
        y1={108}
        x2={168}
        y2={108}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Line
        x1={88}
        y1={144}
        x2={168}
        y2={144}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Path
        d="M205.4,152H220a23.9,23.9,0,0,0,24-24V104a23.9,23.9,0,0,0-24-24H208"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Path
        d="M50.6,152H36a23.9,23.9,0,0,1-24-24V104A23.9,23.9,0,0,1,36,80H48"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
    </Svg>
  );
}

export default FaceMask;
