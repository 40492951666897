/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Polyline, Line, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function UploadSimple(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Polyline
        points="86 82 128 40 170 82"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Line
        x1={128}
        y1={152}
        x2={128}
        y2={40}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Path
        d="M216,152v56a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V152"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
    </Svg>
  );
}

export default UploadSimple;
