/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect } from 'react-native-svg';

import { IconProps } from '../lib';

function DiamondsFour(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Rect
        x={101.1}
        y={35.1}
        width={53.7}
        height={53.74}
        rx={8}
        transform="translate(-6.4 108.7) rotate(-45)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Rect
        x={167.1}
        y={101.1}
        width={53.7}
        height={53.74}
        rx={8}
        transform="translate(-33.7 174.7) rotate(-45)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Rect
        x={35.1}
        y={101.1}
        width={53.7}
        height={53.74}
        rx={8}
        transform="translate(-72.4 81.3) rotate(-45)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Rect
        x={101.1}
        y={167.1}
        width={53.7}
        height={53.74}
        rx={8}
        transform="translate(-99.7 147.3) rotate(-45)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
    </Svg>
  );
}

export default DiamondsFour;
