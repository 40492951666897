/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function WhatsappLogo(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M45.4,177A95.9,95.9,0,1,1,79,210.6h0L45.8,220a7.9,7.9,0,0,1-9.8-9.8L45.4,177Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Path
        d="M80,103.5A23.9,23.9,0,0,1,104,80h3.5a7.9,7.9,0,0,1,6.8,3.9l7.4,12.3a7.8,7.8,0,0,1,.3,7.7l-4.7,9.6h0a36,36,0,0,0,25.2,25.2h0l9.6-4.7a7.8,7.8,0,0,1,7.7.3l12.3,7.4a7.9,7.9,0,0,1,3.9,6.8V152a23.9,23.9,0,0,1-23.5,24A71.9,71.9,0,0,1,80,103.5Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
    </Svg>
  );
}

export default WhatsappLogo;
