/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Polyline, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function Hamburger(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M48,112a80,80,0,0,1,160,0"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Path
        d="M208,160v24a32,32,0,0,1-32,32H80a32,32,0,0,1-32-32V160"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Polyline
        points="28 168 68 152 108 168 148 152 188 168 228 152"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Line
        x1={24}
        y1={112}
        x2={232}
        y2={112}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
    </Svg>
  );
}

export default Hamburger;
