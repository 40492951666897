/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Polygon, Polyline, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function GraduationCap(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Polygon
        points="12 96 128 32 244 96 128 160 12 96"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Polyline
        points="186 240 186 128 128 96"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
      <Path
        d="M224,107v58.2a7.8,7.8,0,0,1-1.9,5.1c-7.7,9.1-39,41.7-94.1,41.7s-86.4-32.6-94.1-41.7a7.8,7.8,0,0,1-1.9-5.1V107"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24}
      />
    </Svg>
  );
}

export default GraduationCap;
